/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CommonInput from "../../components/commonInput";
import { addAdminUser } from "../../store/adminDashboard/actions";
import "./index.scss";

const AddAdminUserModal = ({ visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    mobile: "",
    created_by: "",
    role: ""
  });
  const [shouldUpdate, setshouldUpdate] = useState(false);
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.adminDashboard);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const handleDone = () => {
    dispatch(addAdminUser(payload));
    setTimeout(() => {
      handleCancel();
    }, 2000);
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="addOtheres_model"
      width={500}
    >
      <div className="add_otheres_modal">
        <div style={{ fontWeight: 600, fontSize: "18px" }}>Add Admin User</div>
        <div
          className="content"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <CommonInput
            placeholder="Enter Name"
            name="name"
            type="text"
            value={payload.name}
            handleChange={handleChange}
            style={{ width: "100%" }}
          />
          <CommonInput
            placeholder="Enter Email"
            name="email"
            value={payload.email}
            handleChange={handleChange}
            style={{ width: "100%", marginTop: "16px" }}
          />
          <CommonInput
            placeholder="Enter Mobile Number"
            type="number"
            name="mobile"
            value={payload.mobile}
            handleChange={handleChange}
            style={{ width: "100%", marginTop: "16px" }}
          />
          <CommonInput
            placeholder="Created By"
            name="created_by"
            value={payload.created_by}
            handleChange={handleChange}
            style={{ width: "100%", marginTop: "16px" }}
          />
          <CommonInput
            placeholder="Enter Role"
            name="role"
            value={payload.role}
            handleChange={handleChange}
            style={{ width: "100%", marginTop: "16px" }}
          />
        </div>
        <div className="submit_btnContainer">
          <button
            disabled={loader}
            className="done_btn"
            onClick={() => handleDone()}
            style={{ marginTop: "24px" }}
          >
            {loader ? "Adding..!" : "Add"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddAdminUserModal;

/* eslint-disable */
import { combineReducers } from "redux";
import profile from "./profile/profileReducer";
import login from "./newLogin/newReducer";
import register from "./newRegister/registerReduceres";
import dashboard from "./dashBoard/dashboardReduceres";
import adminDashboard from "./adminDashboard/reducer";

export const AppReducers = combineReducers({
  login,
  register,
  dashboard,
  profile,
  adminDashboard,
});

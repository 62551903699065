/* eslint-disable */
import * as types from "./types";

const INITIAL_STATE = {
  loader: false,
  users: [],
  coupons: [],
  adminList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_USERS_LIST_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        users: action.payload,
      };
    case types.GET_USERS_LIST_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.COUPONS_LIST_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.COUPONS_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        coupons: action.payload,
      };
    case types.COUPONS_LIST_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.ADD_COUPON_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.ADD_COUPON_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case types.ADD_COUPON_FAILURE:
      return {
        ...state,
        loader: false,
      };
    case types.ADMIN_LIST_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.ADMIN_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        adminList: action.payload,
      };
    case types.ADMIN_LIST_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.GET_COUPONS_USERS_REQUEST:
      return {
        ...state,
        couponUsersLoader: true,
        couponUsers: [],
      };
    case types.GET_COUPONS_USERS_SUCCESS:
      return {
        ...state,
        couponUsersLoader: false,
        couponUsers: action.payload,
      };
    case types.GET_COUPONS_USERS_FAILURE:
      return {
        ...state,
        couponUsersLoader: false,
        couponUsers: [],
      };

    case types.UPDATE_ISACTIVE_REQUEST:
        return {
          ...state,
          loader: true,
        };
    case types.UPDATE_ISACTIVE_SUCCESS:
        return {
          ...state,
          loader: false,
        };
    case types.UPDATE_ISACTIVE_FAILURE:
        return {
          ...state,
          loader: false,
        };

    case types.RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

import React from "react";
import { Badge, Dropdown, Space, Table, Spin, Tabs } from "antd";
import { DownOutlined } from "@ant-design/icons";
import CommonInput from "../../components/commonInput";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getCouponsList,
  getCouponsUsers,
} from "../../store/adminDashboard/actions";
import AddCouponsModal from "./addCouponModal";

const items = [
  {
    key: "1",
    label: "Action 1",
  },
  {
    key: "2",
    label: "Action 2",
  },
];

const CouponsComponent = (props) => {
  const dispatch = useDispatch();
  const {
    loader,
    couponUsersLoader,
    couponUsers,
    coupons
  } = useSelector((state) => state.adminDashboard);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setshowModal] = useState(false);

  const expandedRowRender = (e) => {
    // dispatch(getCouponsUsers(e.coupon_name));
    const columns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Status",
        key: "state",
        render: () => <Badge status="success" text="Finished" />,
      },
      {
        title: "Upgrade Status",
        dataIndex: "upgradeNum",
        key: "upgradeNum",
      },
      {
        title: "Action",
        dataIndex: "operation",
        key: "operation",
        render: () => (
          <Space size="middle">
            <a>Pause</a>
            <a>Stop</a>
            <Dropdown
              menu={{
                items,
              }}
            >
              <a>
                More <DownOutlined />
              </a>
            </Dropdown>
          </Space>
        ),
      },
    ];
    const data = [];
    for (let i = 0; i < 3; ++i) {
      data.push({
        key: i.toString(),
        date: "2014-12-24 23:12:00",
        name: "This is production name",
        upgradeNum: "Upgraded: 56",
      });
    }
    return couponUsersLoader ? (
      <Spin />
    ) : (
      <Table columns={columns} dataSource={data} pagination={false} />
    );
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "coupon_name",
      key: "coupon_name",
    },
    {
      title: "Limit",
      dataIndex: "limit",
      key: "limit",
    },
    {
      title: "Usage Count",
      dataIndex: "usage_count",
      key: "usage_count",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (e) => <div>{String(e)}</div>,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Action",
      key: "operation",
      render: () => (
        <div>
          <a style={{ marginRight: "10px" }}>Edit</a>
          <a>Delete</a>
        </div>
      ),
    },
  ];

  const appendKey = (data) => {
    return data.map((item, i) => {
      return { ...item, key: i.toString() };
    });
  };

  const applyFilter = (data) => {
    if (searchValue.trim()) {
      return data.filter((item) =>
        item.coupon_name.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      return data;
    }
  };

  useEffect(() => {
    dispatch(getCouponsList());
  }, []);

  return (
    <Spin size="large" spinning={loader}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            background: "white",
          }}
        >
          <h2>
            Coupons{" "}
            <span
              style={{
                background: "rgba(67, 137, 250, 0.1)",
                color: "#1890ff",
                padding: "0px 10px",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                setshowModal(!showModal);
              }}
            >
              +
            </span>
          </h2>
          <div style={{ width: "30%" }}>
            <CommonInput
              placeholder="Search by name"
              name="coupon"
              type="text"
              value={searchValue}
              handleChange={(e) => {
                setSearchValue(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender,
              defaultExpandedRowKeys: ["0"],
            }}
            dataSource={appendKey(applyFilter(coupons))}
          />
        </div>
        <AddCouponsModal visible={showModal} />
      </div>
    </Spin>
  );
};

export default CouponsComponent;

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import * as LoginActions from "../../store/newLogin/newLoginAction";
import history from "../../routes/History";
import "./Login.scss";
import CommonInput from "../../components/commonInput";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
});

const ResetPassword = ({
  adminEmail,
  adminSession
}) => {
  const dispatch = useDispatch();
  const { loader, userData } = useSelector((state) => state.login);

  const handleLogin = (data) => {
    dispatch(LoginActions.loginRespondRequest({
      email: adminEmail,
      new_password: data.password,
      session: adminSession
    }));
  };

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      history.push("/adminDashboard");
    } else {
      localStorage.clear();
    }
  }, [userData]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        // padding: "0% 5% 0% 25%",
      }}
    >
      <div className="login-section">
        <div
          style={{
            fontSize: "20px",
            fontWeight: 500,
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Reset Password
        </div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={DisplayingErrorMessagesSchema}
          onSubmit={(values) => {
            handleLogin(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <CommonInput name="email" placeholder="Email" disabled value={adminEmail} />
              <div
                className="error_fields"
                style={{
                  color: touched.email && errors.email ? "" : "transparent",
                }}
              >
                {errors?.email}
              </div>
              <CommonInput
                formField
                name="password"
                placeholder="New Password"
                type="password"
              />
              <div
                className="error_fields"
                style={{
                  color:
                    touched.password && errors.password ? "" : "transparent",
                }}
              >
                {errors?.password}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <button type="submit" className="login_btn">
                  Log in
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import * as LoginActions from "../../store/newLogin/newLoginAction";
import history from "../../routes/History";
import "./Login.scss";
import CommonInput from "../../components/commonInput";
import ForgotPassModal from "./forgotPassModal";
import ResetPassword from "./ResetPassword";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
});

const NewLogin = ({
  setUploadResume,
  showUploadResume,
  setRegistrationData,
  handleClick,
}) => {
  const dispatch = useDispatch();
  const { loader, userData, session } = useSelector((state) => state.login);

  const [isModalEmailVisible, setIsModalEmailVisible] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const handleLogin = (data) => {
    dispatch(LoginActions.loginRequest(data));
    setRegistrationData({
      first_name: "",
      last_name: "",
      ...data,
    });
    setUserEmail(data.email);
  };

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      history.push("/adminDashboard");
    }
  }, [userData]);

  return (
    <div className="login-container">
      {session === "" ?
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              // padding: "0% 5% 0% 25%",
            }}
          >
            <div className="login-section">
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  marginBottom: "10px",
                  textAlign: "center",
                }}
              >
                Admin Login
              </div>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={(values) => {
                  handleLogin(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <CommonInput formField name="email" placeholder="Email" />
                    <div
                      className="error_fields"
                      style={{
                        color: touched.email && errors.email ? "" : "transparent",
                      }}
                    >
                      {errors?.email}
                    </div>
                    <CommonInput
                      formField
                      name="password"
                      placeholder="Password"
                      type="password"
                    />
                    <div
                      className="error_fields"
                      style={{
                        color:
                          touched.password && errors.password ? "" : "transparent",
                      }}
                    >
                      {errors?.password}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <button type="submit" className="login_btn">
                        Log in
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setIsModalEmailVisible(!isModalEmailVisible);
                          // history.push("/forgotPassword");
                        }}
                        className="forgotpass_btn"
                      >
                        Forgot Password?
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <ForgotPassModal visible={isModalEmailVisible} />
        </> :
        <>
          <ResetPassword adminEmail={userEmail} adminSession={session} />
        </>
      }
    </div>
  );
};

export default NewLogin;

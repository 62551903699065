import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import history from "../../routes/History";
import personImg from "../../assets/images/person.png";
import * as LoginActions from "../../store/newLogin/newLoginAction";

import "./ResetPassword.scss";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  otp: Yup.string().required("Required"),
  newpass: Yup.string().required("Required"),
  confirmpass: Yup.string().oneOf(
    [Yup.ref("newpass"), null],
    "Passwords must match"
  ),
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [submitClick, setSubmitClick] = useState(false);

  const location = useLocation();
  const error = useSelector((state) => state.login.error);

  useEffect(() => {
    if (error) {
      if (error.status === "ERROR") {
        // console.log("USERDATAATATA", error);
      } else {
        setSubmitClick(true);
      }
    }
  }, [error]);

  const handleResetPass = (values) => {
    dispatch(
      LoginActions.resetPasswordRequest({
        email: location.state.forgotEmail,
        code: parseInt(values.otp, 10),
        new_password: values.newpass,
      })
    );
  };

  const textTile = submitClick ? "Password Changed" : "Reset Password";

  return (
    <div className="resetpass-container">
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          padding: "30px",
        }}
      >
        <h2 style={{ fontWeight: "600" }}>unnlock</h2>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "10px 30px",
        }}
      >
        <div className="login-section">
          <h2 className="forgot_text">{textTile}</h2>
          {!submitClick && (
            <Formik
              initialValues={{
                otp: "",
                newpass: "",
                confirmpass: "",
              }}
              validationSchema={DisplayingErrorMessagesSchema}
              onSubmit={(values) => {
                // console.log(values, "export default");
                handleResetPass(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <Field name="otp" placeholder="Enter OTP" />
                  <div
                    className="error_fields"
                    style={{
                      color: touched.otp && errors.otp ? "" : "transparent",
                    }}
                  >
                    {errors?.otp}
                  </div>
                  <div className="line" />
                  <Field
                    type="password"
                    name="newpass"
                    placeholder="New Password"
                  />
                  <div
                    className="error_fields"
                    style={{
                      color:
                        touched.newpass && errors.newpass ? "" : "transparent",
                    }}
                  >
                    {errors?.newpass}
                  </div>
                  <Field
                    type="password"
                    name="confirmpass"
                    placeholder="Confirm Password"
                  />
                  <div
                    className="error_fields"
                    style={{
                      color:
                        touched.confirmpass && errors.confirmpass
                          ? ""
                          : "transparent",
                    }}
                  >
                    {errors?.confirmpass}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <button type="submit" className="login_btn">
                      Submit
                    </button>
                  </div>
                  <p
                    className="backtext"
                    onClick={() => {
                      history.push("/registration");
                    }}
                  >
                    Back to Login page
                  </p>
                </Form>
              )}
            </Formik>
          )}
          {submitClick && (
            <div>
              <p className="passchange_sucess">
                Password Changed Successfully!
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <button
                  type="submit"
                  className="login_btn"
                  onClick={() => {
                    history.push("/registration");
                  }}
                >
                  Login Now
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="right_side">
          <h2 style={{ fontWeight: 600 }}>
            Identify and win the right career opportunities.
          </h2>
          <p style={{ margin: "40px 0px" }}>
            Dreamers and visionaries from over 100 countries use miined. It's
            time for you to join them. Get a sneak peek at your future!
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <button
              className="tryFree_button"
              onClick={() => {
                history.push("/registration");
              }}
            >
              Try unnlock for free
            </button>
            <button
              className="subscrption_btn"
              onClick={() => history.push("/plans")}
            >
              View Subscription Plans
            </button>
          </div>
        </div>
      </div>
      <img className="person-img_login" src={personImg} alt="person" />
      <div className="footer_login" />
    </div>
  );
};
export default ResetPassword;

const devBaseURLs = {
  authPath: process.env.REACT_APP_BASE_URL,
};

const baseUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://server.unnlock.ai"
    : process.env.REACT_APP_BASE_URL;

const optionalVariableName =
  process.env.REACT_APP_ENV === "production"
    ? "?env=prod"
    : process.env.REACT_APP_ENV === "staging"
    ? "?env=stg"
    : "";
const isAddOptionalParameter =
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "staging"
    ? optionalVariableName
    : "";

const devPaths = {
  login: {
    userLogin: `${devBaseURLs.authPath}/dashboard-service/login`,
    userLoginRespond: `${devBaseURLs.authPath}/dashboard-service/respond-challenge`,
  },
  refreshLogin: {
    refreshLoginUrl: `${devBaseURLs.authPath}/user-service/user/user-refreshlogin`,
    forgotPassUrl: `${devBaseURLs.authPath}/email/send-link`,
    resetPassUrl: `${devBaseURLs.authPath}/email/verify`,
  },
  userRestoreCred: {
    newForgotPassUrl: (email) =>
      `${devBaseURLs.authPath}/user-service/user/user-forgetpassword?email=${email}`,
    newResetPassUrl: `${devBaseURLs.authPath}/user/user-confirmforgetpassword`,
  },
  registration: {
    uploadCV: (email, cvName) =>
      `${devBaseURLs.authPath}/user-service/user/upload-file/file-name=${email}/${cvName}`,
    registerUser: `${devBaseURLs.authPath}/user-service/user/create-user`,
    subscriptionUpdationUrl: `${devBaseURLs.authPath}/subscription-service/create`,
    getUserInformation: `${devBaseURLs.authPath}/dashboard-service/get-admin`,
    getSubsCount: `${devBaseURLs.authPath}/subscription-service/fetch`,
    applyCoupon: `${devBaseURLs.authPath}/subscription-service/subscribe`,
    socialSignUp: `${devBaseURLs.authPath}/user-service/user/social-signup`,
    getAllSocs: `${devBaseURLs.authPath}/search-service/v2/soc-layout`,
    emailVerify: `${devBaseURLs.authPath}/verify-service/initiate-mail-verification`,
  },
  personalityTest: {
    getQuestions: () =>
      `${devBaseURLs.authPath}/quiz-service/quiz/get-questions`,
    getResults: (id) =>
      `${devBaseURLs.authPath}/quiz-service/quiz/result/${id}`,
    submitResults: () => `${devBaseURLs.authPath}/quiz-service/quiz/save`,
  },
  ProfileDetails: {
    putProfilePic: `${devBaseURLs.authPath}/user-service/user/update`,
    getProfilePicUrl: (fileType, fileName) =>
      `${devBaseURLs.authPath}/user-service/user/upload/${fileType}/${fileName}`,
    addProfilePic: (url) => `${url}`,
    uploadProfilePicUrl: `${devBaseURLs.authPath}/image-service/upload-pic`,
    getSubscription: `${devBaseURLs.authPath}/user-service/user/get-subscriptions`,
    createCustomer: `${devBaseURLs.authPath}/payment-service/stripe/customer`,
    createSubscription: (customerId, priceId) =>
      `${devBaseURLs.authPath}/payment-service/create-subscription/${customerId}/${priceId}`,
    cancelSubscription: (subsId) =>
      `${devBaseURLs.authPath}/payment-service/cancel-subscription/${subsId}`,
    getCardDetails: (subpaumentId) =>
      `${devBaseURLs.authPath}/payment-service/stripe/card/${subpaumentId}`,
    changePassword: `${devBaseURLs.authPath}/user-service/user/password-change`,
    forgotPassword: (userName) =>
      `${devBaseURLs.authPath}/user-service/user/user-forgetpassword/${userName}`,
    getPaymentMethods: (custId) =>
      `${devBaseURLs.authPath}/payment-service/stripe/card-list/${custId}`,
    attachPaymentMethod: (custId, payMethod) =>
      `${devBaseURLs.authPath}/payment-service/stripe/attach-card/${custId}/${payMethod}`,
    detachCard: (payMethod) =>
      `${devBaseURLs.authPath}/payment-service/stripe/detach-card/${payMethod}`,
    updateSubTonewPayment: (subId, payID) =>
      `${devBaseURLs.authPath}/payment-service/stripe/update-subscription/${subId}/${payID}`,
    OverLayAccept: `${devBaseURLs.authPath}/user-service/user/update`,
    saveCompanyDatils: `${devBaseURLs.authPath}/user-service/user/update`,
    getCompanyFilteres: `${devBaseURLs.authPath}/search-service/v2/company_filters`,
  },

  adminDashboard: {
    getUseres: () => `${devBaseURLs.authPath}/dashboard-service/list-users`,
    getCoupons: () => `${devBaseURLs.authPath}/dashboard-service/list-coupons`,
    addCoupons: () => `${devBaseURLs.authPath}/dashboard-service/create-coupon`,
    getCouponsUsers: (name) =>
      `${devBaseURLs.authPath}/dashboard-service/list-coupon-users?coupon=${name}`,
    addAdminUser: () => `${devBaseURLs.authPath}/dashboard-service/create-admin`,
    addAdminLists: () => `${devBaseURLs.authPath}/dashboard-service/list-admins`,
    updateIsActive: () => `${devBaseURLs.authPath}/dashboard-service/admin-update`,
  },
};

const config =
  process.env.REACT_APP_STAGE === "production" ? devPaths : devPaths;

export default {
  ...config,
};

/* eslint-disable */
import { put, takeLatest, call } from "redux-saga/effects";
import * as ACTIONS from "./actions";
import * as TYPES from "./types";
import Network from "../../network";
import unnlockNotification from "../../components/notification";

const { API, Config } = Network;

function* handleUsersList(action) {
  try {
    const getUseres = Config.adminDashboard.getUseres();
    const result = yield call(API.get, getUseres, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.getUsersListSuccess(result));
  } catch (error) {
    yield put(ACTIONS.getUsersListFailure(error));
  }
}

function* handleCouponsList(action) {
  try {
    const getCoupons = Config.adminDashboard.getCoupons();
    const result = yield call(API.get, getCoupons, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.getCouponsListSuccess(result));
  } catch (error) {
    yield put(ACTIONS.getCouponsListFailure(error));
  }
}

function* handleAddCoupons(action) {
  try {
    const addCoupons = Config.adminDashboard.addCoupons();
    const result = yield call(API.post, addCoupons, action.payload, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.addCouponSuccess(result));
    unnlockNotification(
      `Coupon - ${action.payload.coupon_name} added successfully!`,
      "success"
    );
  } catch (error) {
    unnlockNotification(`Something went wrong`, "error");
    yield put(ACTIONS.addCouponFailure(error));
  }
}

function* handleAddAdminUser(action) {
  try {
    const addAdminUserUrl = Config.adminDashboard.addAdminUser();
    const result = yield call(API.post, addAdminUserUrl, action.payload, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.addAdminUserSuccess(result));
    unnlockNotification(
      `Admin User - ${action.payload.name} created successfully!`,
      "success"
    );
  } catch (error) {
    unnlockNotification(`Something went wrong`, "error");
    yield put(ACTIONS.addAdminUserFailure(error));
  }
}

function* handleCouponUsers(action) {
  try {
    const getUseres = Config.adminDashboard.getCouponsUsers(action.payload);
    const result = yield call(API.get, getUseres, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.getCouponsUsersSuccess(result));
  } catch (error) {
    yield put(ACTIONS.getCouponsUsersFailure(error));

  }
}

function* handleAdminList(action) {
  try {
    const getAdminLists = Config.adminDashboard.addAdminLists();
    const result = yield call(API.get, getAdminLists, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.adminListSuccess(result));
  } catch (error) {
    yield put(ACTIONS.adminListFailure(error));
  }
}

function* updateIsActive(action) {
  try {
    const updateIsActive = Config.adminDashboard.updateIsActive();
    const result = yield call(API.post, updateIsActive, action.payload, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.updateIsActiveSuccess(result));
    //yield put(ACTIONS.getAdminList());
  } catch (error) {
    yield put(ACTIONS.updateIsActiveFailure(error));
  }
}

export function* requestUsersList() {
  yield takeLatest(TYPES.GET_USERS_LIST_REQUEST, handleUsersList);
}
export function* requestCouponsList() {
  yield takeLatest(TYPES.COUPONS_LIST_REQUEST, handleCouponsList);
}
export function* requestAddCoupons() {
  yield takeLatest(TYPES.ADD_COUPON_REQUEST, handleAddCoupons);
}
export function* requestAddAdminUser() {
  yield takeLatest(TYPES.ADD_ADMIN_USER_REQUEST, handleAddAdminUser);
}
export function* requestCouponUsers() {
  yield takeLatest(TYPES.ADD_COUPON_REQUEST, handleCouponUsers);
}
export function* requestAdminLists() {
  yield takeLatest(TYPES.ADMIN_LIST_REQUEST, handleAdminList);
}
export function* updateIsActiveUser() {
  yield takeLatest(TYPES.ADMIN_LIST_REQUEST, updateIsActive);
}



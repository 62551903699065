import React from "react";
import { Space, Spin, Table, Switch } from "antd";
import CommonInput from "../../components/commonInput";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getCouponsList,
  getUsersList,
  getAdminList,
  updateIsActive
} from "../../store/adminDashboard/actions";
import AddAdminUserModal from "./addAdminUserModal";

const { Column } = Table;

const AdminUsers = (props) => {
  const dispatch = useDispatch();
  const { loader, adminList } = useSelector(
    (state) => state.adminDashboard
  );
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setshowModal] = useState(false);

  const applyFilter = (data) => {
    if (searchValue.trim()) {
      return data.filter(
        (item) =>
          item.email.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.created_by.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.role.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      return data;
    }
  };

  const onChangeIsActive = (checked, adminObj) => {
    console.log("LSLDLFS", checked, adminObj)
    // dispatch(updateIsActive({
    //   email: adminObj.email,
    //   is_active: checked,
    //   mobile: adminObj.mobile,
    //   role: adminObj.role
    // }));
  }

  useEffect(() => {
    dispatch(getAdminList());
  }, []);

  return (
    <Spin size="large" spinning={loader}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            background: "white",
          }}
        >
          <h2>
            Admin Users{" "}
            <span
              style={{
                background: "rgba(67, 137, 250, 0.1)",
                color: "#1890ff",
                padding: "0px 10px",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                setshowModal(!showModal);
              }}
            >
              +
            </span>
          </h2>
          <div style={{ width: "30%" }}>
            <CommonInput
              placeholder="Search by name"
              name="coupon"
              type="text"
              value={searchValue}
              handleChange={(e) => {
                setSearchValue(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div>
          <Table dataSource={applyFilter(adminList)}>
            <Column
              title="Created By"
              dataIndex="created_by"
              key="created_by"
            />
            <Column title="Mobile" dataIndex="mobile" key="mobile" />
            <Column title="Email" dataIndex="email" key="email" />
            <Column
              title="Created Date"
              dataIndex="created_date"
              key="created_date"
            />
            <Column
              title="Role"
              dataIndex="role"
              key="role"
            />
            <Column
              title="IsActive"
              key="is_active"
              dataIndex="is_active"
              render={(_, record) => (
                <Space size="middle">
                  <Switch defaultChecked onChange={(e) => onChangeIsActive(e, record)} checked={record.is_active} />
                </Space>
              )}
            />
          </Table>
        </div>
        <AddAdminUserModal visible={showModal} />
      </div>
    </Spin>
  );
};

export default AdminUsers;

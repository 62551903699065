import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import _ from "lodash";

const UserCharts = ({ users }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let data = [];
    if (users?.length) {
      data = _(users)
        .groupBy("tier_id")
        ?.map((value, key) => ({ tier_id: key, users: value }))
        ?.value();
      setChartData(data);
    }
  }, [users]);

  const pieChartPption = {
    title: {
      text: "Subscriptions Stats",
      subtext: "Based on tier id's",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        // name: "Access From",
        type: "pie",
        radius: "50%",
        data: chartData?.map((item) => {
          return {
            name: item?.tier_id,
            value: item?.users?.length,
          };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const barChartOption = {
    title: {
      text: "Subscriptions Stats",
      subtext: "Based on tier id's",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    xAxis: {
      type: "category",
      data: ["Subscriptions"],
    },
    yAxis: {
      type: "value",
    },
    series: chartData.map((item) => {
      return {
        name: item.tier_id,
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        data: [item?.users?.length],
        barWidth: "100",
      };
    }),
  };

  return (
    <div
      style={{
        background: "white",
        padding: "10px",
        marginTop: "20px",
        borderRadius: "10px",
      }}
    >
      <ReactECharts option={pieChartPption} />
      <ReactECharts option={barChartOption} />
    </div>
  );
};

export default UserCharts;

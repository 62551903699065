import { fork, all } from "redux-saga/effects";
import * as RegisterSaga from "./newRegister/registerSaga";
import * as NewLoginSaga from "./newLogin/newLoginSaga";
import * as ProfileSaga from "./profile/profileSaga";
import * as AdminDashboardSaga from "./adminDashboard/saga";

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(NewLoginSaga),
      ...Object.values(RegisterSaga),
      ...Object.values(ProfileSaga),
      ...Object.values(AdminDashboardSaga),
    ].map(fork)
  );
}
